<template>
  <div class="w-full h-screen flex flex-col bg-white overflow-hidden" id="demo_wrap">
    <div class="player-list flex flex-col md:justify-center w-full m-auto relative z-10">
        <div class="player-current flex-row md:flex-col w-full">
            <div class="m-auto">
                <div class="logo-container m-auto block w-full relative pt-12 pb-8 mb-6">
                    <svg class="logo m-auto" id="Grupo_2" data-name="Grupo 2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="260.546" height="69.251" viewBox="0 0 260.546 69.251">
                        <defs>
                            <clipPath id="clip-path">
                            <rect id="Rectángulo_1" data-name="Rectángulo 1" width="260.546" height="69.251" fill="none"/>
                            </clipPath>
                        </defs>
                        <g id="Grupo_1" data-name="Grupo 1" clip-path="url(#clip-path)">
                            <path id="Trazado_1" data-name="Trazado 1" d="M389.712,37.446c.325,4.309,1.9,8.664,5.976,10.794a10.694,10.694,0,0,0,10.887-1.112c3.011-2.594,3.985-6.116,4.263-9.868l.047-30.392h10.284l-.046,31.226c-.371,7.969-3.846,16.307-11.768,19.551-7.367,2.918-17.328,2.176-23.257-3.383-4.494-4.354-6.209-9.821-6.672-15.8l-.046-18.67V6.868h10.284Z" transform="translate(-196.537 -3.558)" fill="#003d8c"/>
                            <path id="Trazado_2" data-name="Trazado 2" d="M389.712,37.446c.325,4.309,1.9,8.664,5.976,10.794a10.694,10.694,0,0,0,10.887-1.112c3.011-2.594,3.985-6.116,4.263-9.868l.047-30.392h10.284l-.046,31.226c-.371,7.969-3.846,16.307-11.768,19.551-7.367,2.918-17.328,2.176-23.257-3.383-4.494-4.354-6.209-9.821-6.672-15.8l-.046-18.67V6.868h10.284Z" transform="translate(-196.537 -3.558)" fill="none" stroke="#003d8c" stroke-width="0.395"/>
                            <path id="Trazado_3" data-name="Trazado 3" d="M493.556,57.911a6.651,6.651,0,0,1-3.1,4.309,8.329,8.329,0,0,1-7.644-1.159,7.878,7.878,0,0,1-1.575-1.714V44.8a7.305,7.305,0,0,1,7.6-3.1,5.006,5.006,0,0,1,2.965,1.854c2.779,3.845,2.687,9.682,1.761,14.362m8.432-18.023a14.15,14.15,0,0,0-7.737-7.181c-4.4-1.483-9.729-.742-13.065,2.455V32.753h-10.1V85.662l10.1-5,.047-11.305a13.03,13.03,0,0,0,10.934,2.734c4.679-.555,9.034-4.864,10.563-9.219a32.209,32.209,0,0,0-.741-22.98" transform="translate(-244.044 -16.564)" fill="#003d8c"/>
                            <path id="Trazado_4" data-name="Trazado 4" d="M493.556,57.911a6.651,6.651,0,0,1-3.1,4.309,8.329,8.329,0,0,1-7.644-1.159,7.878,7.878,0,0,1-1.575-1.714V44.8a7.305,7.305,0,0,1,7.6-3.1,5.006,5.006,0,0,1,2.965,1.854C494.575,47.393,494.482,53.23,493.556,57.911Zm8.432-18.023a14.15,14.15,0,0,0-7.737-7.181c-4.4-1.483-9.729-.742-13.065,2.455V32.753h-10.1V85.662l10.1-5,.047-11.305a13.03,13.03,0,0,0,10.934,2.734c4.679-.555,9.034-4.864,10.563-9.219A32.209,32.209,0,0,0,501.988,39.888Z" transform="translate(-244.044 -16.564)" fill="none" stroke="#003d8c" stroke-width="0.395"/>
                            <path id="Trazado_5" data-name="Trazado 5" d="M316.629,29.044c3.2-4.54,6.949-9.081,10.378-13.667h12.045L329.091,27.7l11.166,26.362H329.091l-7.32-17.791-4.956,6.069-.046,11.722H306.621V5.416L316.768.32l-.047,26.547Z" transform="translate(-158.844 -0.166)" fill="#dd121f"/>
                            <path id="Trazado_6" data-name="Trazado 6" d="M316.629,29.044c3.2-4.54,6.949-9.081,10.378-13.667h12.045L329.091,27.7l11.166,26.362H329.091l-7.32-17.791-4.956,6.069-.046,11.722H306.621V5.416L316.768.32l-.047,26.547Z" transform="translate(-158.844 -0.166)" fill="none" stroke="#dd121f" stroke-width="0.395"/>
                            <path id="Trazado_7" data-name="Trazado 7" d="M39.5,11.1c-2.456,2.409-4.957,4.772-7.413,7.227-4.077-4.355-9.775-6.394-15.659-4.818-1.436.417-3.1,1.621-3.382,3.2A3.943,3.943,0,0,0,14.159,20.6a12.81,12.81,0,0,0,4.494,2.085c8.293,2.178,19.922,3.568,21.728,14.131.834,6.022-.556,11.814-5.421,15.566-7.552,5.19-19.875,5.05-27.751.7A20.088,20.088,0,0,1,.26,46.912l7.459-6.624c4.633,5.559,12.138,7.042,18.949,5.1A4.907,4.907,0,0,0,29.818,42c.185-1.528.231-3.289-.834-4.448-2.919-2.5-6.672-2.918-10.193-3.937-6.023-1.436-13.25-3.2-15.659-9.914C1.465,18.42,2.113,12.073,6.329,8.227,12.352,2.344,22.637,1.788,30.235,4.66A25.821,25.821,0,0,1,39.5,11.1" transform="translate(-0.135 -1.541)" fill="#dd121f"/>
                            <path id="Trazado_8" data-name="Trazado 8" d="M39.5,11.1c-2.456,2.409-4.957,4.772-7.413,7.227-4.077-4.355-9.775-6.394-15.659-4.818-1.436.417-3.1,1.621-3.382,3.2A3.943,3.943,0,0,0,14.159,20.6a12.81,12.81,0,0,0,4.494,2.085c8.293,2.178,19.922,3.568,21.728,14.131.834,6.022-.556,11.814-5.421,15.566-7.552,5.19-19.875,5.05-27.751.7A20.088,20.088,0,0,1,.26,46.912l7.459-6.624c4.633,5.559,12.138,7.042,18.949,5.1A4.907,4.907,0,0,0,29.818,42c.185-1.528.231-3.289-.834-4.448-2.919-2.5-6.672-2.918-10.193-3.937-6.023-1.436-13.25-3.2-15.659-9.914C1.465,18.42,2.113,12.073,6.329,8.227,12.352,2.344,22.637,1.788,30.235,4.66A25.821,25.821,0,0,1,39.5,11.1Z" transform="translate(-0.135 -1.541)" fill="none" stroke="#dd121f" stroke-width="0.395"/>
                            <path id="Trazado_9" data-name="Trazado 9" d="M184.228,45.6h-13.2a7.611,7.611,0,0,1,3.8-6.347,6.571,6.571,0,0,1,8.525,3.012,8.677,8.677,0,0,1,.881,3.336m6.949-7.922a16.613,16.613,0,0,0-6.162-6.116,16.242,16.242,0,0,0-16.725,1.39c-7.412,5.7-8.942,15.382-6.811,24.184,1.2,5.607,6.347,11.212,12.046,12.417,7.088,1.713,14.409-.047,19.134-5.607l-7.227-6.068-.416.463a9.378,9.378,0,0,1-10.054,1.437,7.18,7.18,0,0,1-4.077-6.532H194a26.741,26.741,0,0,0-2.826-15.568" transform="translate(-83.197 -15.474)" fill="#dd121f"/>
                            <path id="Trazado_10" data-name="Trazado 10" d="M184.228,45.6h-13.2a7.611,7.611,0,0,1,3.8-6.347,6.571,6.571,0,0,1,8.525,3.012A8.677,8.677,0,0,1,184.228,45.6Zm6.949-7.922a16.613,16.613,0,0,0-6.162-6.116,16.242,16.242,0,0,0-16.725,1.39c-7.412,5.7-8.942,15.382-6.811,24.184,1.2,5.607,6.347,11.212,12.046,12.417,7.088,1.713,14.409-.047,19.134-5.607l-7.227-6.068-.416.463a9.378,9.378,0,0,1-10.054,1.437,7.18,7.18,0,0,1-4.077-6.532H194A26.741,26.741,0,0,0,191.178,37.68Z" transform="translate(-83.197 -15.474)" fill="none" stroke="#dd121f" stroke-width="0.395"/>
                            <path id="Trazado_11" data-name="Trazado 11" d="M110.4,55.832c-.51,1.621-1.39,3.474-3.151,4.307a8.215,8.215,0,0,1-8.663-2.27l-.463-.6V42.673a7.237,7.237,0,0,1,7.552-3.1,4.874,4.874,0,0,1,2.965,1.854c2.872,3.845,2.687,9.682,1.761,14.409m8.478-18.069a14.074,14.074,0,0,0-7.784-7.181c-4.4-1.483-9.729-.741-13.019,2.456V30.627H87.929V83.535l10.146-5c0-3.8-.093-7.644.093-11.305a12.577,12.577,0,0,0,6.393,2.873,13.7,13.7,0,0,0,13.9-7c3.892-7.134,3.753-17.975.417-25.342" transform="translate(-45.551 -15.463)" fill="#dd121f"/>
                            <path id="Trazado_12" data-name="Trazado 12" d="M110.4,55.832c-.51,1.621-1.39,3.474-3.151,4.307a8.215,8.215,0,0,1-8.663-2.27l-.463-.6V42.673a7.237,7.237,0,0,1,7.552-3.1,4.874,4.874,0,0,1,2.965,1.854C111.511,45.268,111.325,51.105,110.4,55.832Zm8.478-18.069a14.074,14.074,0,0,0-7.784-7.181c-4.4-1.483-9.729-.741-13.019,2.456V30.627H87.929V83.535l10.146-5c0-3.8-.093-7.644.093-11.305a12.577,12.577,0,0,0,6.393,2.873,13.7,13.7,0,0,0,13.9-7C122.352,55.97,122.213,45.129,118.877,37.762Z" transform="translate(-45.551 -15.463)" fill="none" stroke="#dd121f" stroke-width="0.395"/>
                            <path id="Trazado_13" data-name="Trazado 13" d="M255.465,57.942a8.8,8.8,0,0,1-9.451,3.243,4.712,4.712,0,0,1-3.289-3.1,5.476,5.476,0,0,1,.788-4.866c2.455-2.5,6.856-1.76,9.914-1.066a11.69,11.69,0,0,1,2.038.974Zm8.479-21.5a12.156,12.156,0,0,0-3.151-3.567c-7.088-4.912-18.532-3.66-25.388.787l3.938,7.089a18.961,18.961,0,0,1,4.447-1.9c3.058-.648,7.413-1.529,10.1.556,1.668,1.39,1.668,3.614,1.529,5.653-5.791-2.595-13.992-1.946-18.949,1.992-4.262,3.289-4.911,9.22-3.428,14.13,1.158,4.124,5.421,7.506,9.451,8.433,4.587,1.2,9.637.324,13.019-2.826v2.5H265.53l.031-26.833a15.944,15.944,0,0,0-1.617-6.015" transform="translate(-120.353 -15.397)" fill="#dd121f"/>
                            <path id="Trazado_14" data-name="Trazado 14" d="M255.465,57.942a8.8,8.8,0,0,1-9.451,3.243,4.712,4.712,0,0,1-3.289-3.1,5.476,5.476,0,0,1,.788-4.866c2.455-2.5,6.856-1.76,9.914-1.066a11.69,11.69,0,0,1,2.038.974Zm8.479-21.5a12.156,12.156,0,0,0-3.151-3.567c-7.088-4.912-18.532-3.66-25.388.787l3.938,7.089a18.961,18.961,0,0,1,4.447-1.9c3.058-.648,7.413-1.529,10.1.556,1.668,1.39,1.668,3.614,1.529,5.653-5.791-2.595-13.992-1.946-18.949,1.992-4.262,3.289-4.911,9.22-3.428,14.13,1.158,4.124,5.421,7.506,9.451,8.433,4.587,1.2,9.637.324,13.019-2.826v2.5H265.53l.031-26.833A15.944,15.944,0,0,0,263.944,36.445Z" transform="translate(-120.353 -15.397)" fill="none" stroke="#dd121f" stroke-width="0.395"/>
                        </g>
                    </svg>
                </div>
            </div>
      </div>
      <div class="forbidden flex-row md:flex-col w-full h-full">
        No existe el número
      </div>
      <div class="footer">
            <div @click="toggleLegal" class="legal">
                <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" />
                </svg>
            </div>
            <a href="https://www.rba.es/general/aviso-legal-revistas_177" title="Aviso legal" target="_blank">Aviso legal</a>
            <a href="https://www.rba.es/politica-privacidad" title="Staff" target="_blank">Política de privacidad</a>
            <a href="javascript:Didomi.preferences.show()">Gestiona tus cookies</a>
            <a href="https://www.rba.es/general/politica-cookies_200" target="_blank">Política de cookies</a>
        </div>
    </div>
  </div>
</template>


<script>


export default {
    mounted() {
    },
    setup() {

    }
}
</script>

<style>
    .forbidden {
        font-size: 3rem; letter-spacing: -1px;
        line-height: 1.4;
        font-weight: 600;
        color: #DD1A1E;
        padding: 6rem 6rem 16rem;
        max-width: 32rem;
        margin: auto;
    }

    .player-current {
        max-width: initial !important;
        border: none !important;
    }
</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import md5 from './plugins/md5'

import '@/assets/css/style.css'

createApp(App)
  .use(router)
  .use(md5)
  .mount('#app')
<template>
  <div class="w-full h-screen flex flex-col bg-white overflow-hidden" :id="showLegal ? 'show-legal' : 'player'">
    <div class="player-list flex flex-col md:flex-row md:justify-center w-full m-auto relative z-10">
        <div class="player-current flex-row md:flex-col w-full md:w-5/12">
            <div class="m-auto">
                <div class="logo-container m-auto block w-full relative pt-12 pb-8 mb-6">
                    <svg class="logo m-auto" id="Grupo_2" data-name="Grupo 2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="260.546" height="69.251" viewBox="0 0 260.546 69.251">
                        <defs>
                            <clipPath id="clip-path">
                            <rect id="Rectángulo_1" data-name="Rectángulo 1" width="260.546" height="69.251" fill="none"/>
                            </clipPath>
                        </defs>
                        <g id="Grupo_1" data-name="Grupo 1" clip-path="url(#clip-path)">
                            <path id="Trazado_1" data-name="Trazado 1" d="M389.712,37.446c.325,4.309,1.9,8.664,5.976,10.794a10.694,10.694,0,0,0,10.887-1.112c3.011-2.594,3.985-6.116,4.263-9.868l.047-30.392h10.284l-.046,31.226c-.371,7.969-3.846,16.307-11.768,19.551-7.367,2.918-17.328,2.176-23.257-3.383-4.494-4.354-6.209-9.821-6.672-15.8l-.046-18.67V6.868h10.284Z" transform="translate(-196.537 -3.558)" fill="#003d8c"/>
                            <path id="Trazado_2" data-name="Trazado 2" d="M389.712,37.446c.325,4.309,1.9,8.664,5.976,10.794a10.694,10.694,0,0,0,10.887-1.112c3.011-2.594,3.985-6.116,4.263-9.868l.047-30.392h10.284l-.046,31.226c-.371,7.969-3.846,16.307-11.768,19.551-7.367,2.918-17.328,2.176-23.257-3.383-4.494-4.354-6.209-9.821-6.672-15.8l-.046-18.67V6.868h10.284Z" transform="translate(-196.537 -3.558)" fill="none" stroke="#003d8c" stroke-width="0.395"/>
                            <path id="Trazado_3" data-name="Trazado 3" d="M493.556,57.911a6.651,6.651,0,0,1-3.1,4.309,8.329,8.329,0,0,1-7.644-1.159,7.878,7.878,0,0,1-1.575-1.714V44.8a7.305,7.305,0,0,1,7.6-3.1,5.006,5.006,0,0,1,2.965,1.854c2.779,3.845,2.687,9.682,1.761,14.362m8.432-18.023a14.15,14.15,0,0,0-7.737-7.181c-4.4-1.483-9.729-.742-13.065,2.455V32.753h-10.1V85.662l10.1-5,.047-11.305a13.03,13.03,0,0,0,10.934,2.734c4.679-.555,9.034-4.864,10.563-9.219a32.209,32.209,0,0,0-.741-22.98" transform="translate(-244.044 -16.564)" fill="#003d8c"/>
                            <path id="Trazado_4" data-name="Trazado 4" d="M493.556,57.911a6.651,6.651,0,0,1-3.1,4.309,8.329,8.329,0,0,1-7.644-1.159,7.878,7.878,0,0,1-1.575-1.714V44.8a7.305,7.305,0,0,1,7.6-3.1,5.006,5.006,0,0,1,2.965,1.854C494.575,47.393,494.482,53.23,493.556,57.911Zm8.432-18.023a14.15,14.15,0,0,0-7.737-7.181c-4.4-1.483-9.729-.742-13.065,2.455V32.753h-10.1V85.662l10.1-5,.047-11.305a13.03,13.03,0,0,0,10.934,2.734c4.679-.555,9.034-4.864,10.563-9.219A32.209,32.209,0,0,0,501.988,39.888Z" transform="translate(-244.044 -16.564)" fill="none" stroke="#003d8c" stroke-width="0.395"/>
                            <path id="Trazado_5" data-name="Trazado 5" d="M316.629,29.044c3.2-4.54,6.949-9.081,10.378-13.667h12.045L329.091,27.7l11.166,26.362H329.091l-7.32-17.791-4.956,6.069-.046,11.722H306.621V5.416L316.768.32l-.047,26.547Z" transform="translate(-158.844 -0.166)" fill="#dd121f"/>
                            <path id="Trazado_6" data-name="Trazado 6" d="M316.629,29.044c3.2-4.54,6.949-9.081,10.378-13.667h12.045L329.091,27.7l11.166,26.362H329.091l-7.32-17.791-4.956,6.069-.046,11.722H306.621V5.416L316.768.32l-.047,26.547Z" transform="translate(-158.844 -0.166)" fill="none" stroke="#dd121f" stroke-width="0.395"/>
                            <path id="Trazado_7" data-name="Trazado 7" d="M39.5,11.1c-2.456,2.409-4.957,4.772-7.413,7.227-4.077-4.355-9.775-6.394-15.659-4.818-1.436.417-3.1,1.621-3.382,3.2A3.943,3.943,0,0,0,14.159,20.6a12.81,12.81,0,0,0,4.494,2.085c8.293,2.178,19.922,3.568,21.728,14.131.834,6.022-.556,11.814-5.421,15.566-7.552,5.19-19.875,5.05-27.751.7A20.088,20.088,0,0,1,.26,46.912l7.459-6.624c4.633,5.559,12.138,7.042,18.949,5.1A4.907,4.907,0,0,0,29.818,42c.185-1.528.231-3.289-.834-4.448-2.919-2.5-6.672-2.918-10.193-3.937-6.023-1.436-13.25-3.2-15.659-9.914C1.465,18.42,2.113,12.073,6.329,8.227,12.352,2.344,22.637,1.788,30.235,4.66A25.821,25.821,0,0,1,39.5,11.1" transform="translate(-0.135 -1.541)" fill="#dd121f"/>
                            <path id="Trazado_8" data-name="Trazado 8" d="M39.5,11.1c-2.456,2.409-4.957,4.772-7.413,7.227-4.077-4.355-9.775-6.394-15.659-4.818-1.436.417-3.1,1.621-3.382,3.2A3.943,3.943,0,0,0,14.159,20.6a12.81,12.81,0,0,0,4.494,2.085c8.293,2.178,19.922,3.568,21.728,14.131.834,6.022-.556,11.814-5.421,15.566-7.552,5.19-19.875,5.05-27.751.7A20.088,20.088,0,0,1,.26,46.912l7.459-6.624c4.633,5.559,12.138,7.042,18.949,5.1A4.907,4.907,0,0,0,29.818,42c.185-1.528.231-3.289-.834-4.448-2.919-2.5-6.672-2.918-10.193-3.937-6.023-1.436-13.25-3.2-15.659-9.914C1.465,18.42,2.113,12.073,6.329,8.227,12.352,2.344,22.637,1.788,30.235,4.66A25.821,25.821,0,0,1,39.5,11.1Z" transform="translate(-0.135 -1.541)" fill="none" stroke="#dd121f" stroke-width="0.395"/>
                            <path id="Trazado_9" data-name="Trazado 9" d="M184.228,45.6h-13.2a7.611,7.611,0,0,1,3.8-6.347,6.571,6.571,0,0,1,8.525,3.012,8.677,8.677,0,0,1,.881,3.336m6.949-7.922a16.613,16.613,0,0,0-6.162-6.116,16.242,16.242,0,0,0-16.725,1.39c-7.412,5.7-8.942,15.382-6.811,24.184,1.2,5.607,6.347,11.212,12.046,12.417,7.088,1.713,14.409-.047,19.134-5.607l-7.227-6.068-.416.463a9.378,9.378,0,0,1-10.054,1.437,7.18,7.18,0,0,1-4.077-6.532H194a26.741,26.741,0,0,0-2.826-15.568" transform="translate(-83.197 -15.474)" fill="#dd121f"/>
                            <path id="Trazado_10" data-name="Trazado 10" d="M184.228,45.6h-13.2a7.611,7.611,0,0,1,3.8-6.347,6.571,6.571,0,0,1,8.525,3.012A8.677,8.677,0,0,1,184.228,45.6Zm6.949-7.922a16.613,16.613,0,0,0-6.162-6.116,16.242,16.242,0,0,0-16.725,1.39c-7.412,5.7-8.942,15.382-6.811,24.184,1.2,5.607,6.347,11.212,12.046,12.417,7.088,1.713,14.409-.047,19.134-5.607l-7.227-6.068-.416.463a9.378,9.378,0,0,1-10.054,1.437,7.18,7.18,0,0,1-4.077-6.532H194A26.741,26.741,0,0,0,191.178,37.68Z" transform="translate(-83.197 -15.474)" fill="none" stroke="#dd121f" stroke-width="0.395"/>
                            <path id="Trazado_11" data-name="Trazado 11" d="M110.4,55.832c-.51,1.621-1.39,3.474-3.151,4.307a8.215,8.215,0,0,1-8.663-2.27l-.463-.6V42.673a7.237,7.237,0,0,1,7.552-3.1,4.874,4.874,0,0,1,2.965,1.854c2.872,3.845,2.687,9.682,1.761,14.409m8.478-18.069a14.074,14.074,0,0,0-7.784-7.181c-4.4-1.483-9.729-.741-13.019,2.456V30.627H87.929V83.535l10.146-5c0-3.8-.093-7.644.093-11.305a12.577,12.577,0,0,0,6.393,2.873,13.7,13.7,0,0,0,13.9-7c3.892-7.134,3.753-17.975.417-25.342" transform="translate(-45.551 -15.463)" fill="#dd121f"/>
                            <path id="Trazado_12" data-name="Trazado 12" d="M110.4,55.832c-.51,1.621-1.39,3.474-3.151,4.307a8.215,8.215,0,0,1-8.663-2.27l-.463-.6V42.673a7.237,7.237,0,0,1,7.552-3.1,4.874,4.874,0,0,1,2.965,1.854C111.511,45.268,111.325,51.105,110.4,55.832Zm8.478-18.069a14.074,14.074,0,0,0-7.784-7.181c-4.4-1.483-9.729-.741-13.019,2.456V30.627H87.929V83.535l10.146-5c0-3.8-.093-7.644.093-11.305a12.577,12.577,0,0,0,6.393,2.873,13.7,13.7,0,0,0,13.9-7C122.352,55.97,122.213,45.129,118.877,37.762Z" transform="translate(-45.551 -15.463)" fill="none" stroke="#dd121f" stroke-width="0.395"/>
                            <path id="Trazado_13" data-name="Trazado 13" d="M255.465,57.942a8.8,8.8,0,0,1-9.451,3.243,4.712,4.712,0,0,1-3.289-3.1,5.476,5.476,0,0,1,.788-4.866c2.455-2.5,6.856-1.76,9.914-1.066a11.69,11.69,0,0,1,2.038.974Zm8.479-21.5a12.156,12.156,0,0,0-3.151-3.567c-7.088-4.912-18.532-3.66-25.388.787l3.938,7.089a18.961,18.961,0,0,1,4.447-1.9c3.058-.648,7.413-1.529,10.1.556,1.668,1.39,1.668,3.614,1.529,5.653-5.791-2.595-13.992-1.946-18.949,1.992-4.262,3.289-4.911,9.22-3.428,14.13,1.158,4.124,5.421,7.506,9.451,8.433,4.587,1.2,9.637.324,13.019-2.826v2.5H265.53l.031-26.833a15.944,15.944,0,0,0-1.617-6.015" transform="translate(-120.353 -15.397)" fill="#dd121f"/>
                            <path id="Trazado_14" data-name="Trazado 14" d="M255.465,57.942a8.8,8.8,0,0,1-9.451,3.243,4.712,4.712,0,0,1-3.289-3.1,5.476,5.476,0,0,1,.788-4.866c2.455-2.5,6.856-1.76,9.914-1.066a11.69,11.69,0,0,1,2.038.974Zm8.479-21.5a12.156,12.156,0,0,0-3.151-3.567c-7.088-4.912-18.532-3.66-25.388.787l3.938,7.089a18.961,18.961,0,0,1,4.447-1.9c3.058-.648,7.413-1.529,10.1.556,1.668,1.39,1.668,3.614,1.529,5.653-5.791-2.595-13.992-1.946-18.949,1.992-4.262,3.289-4.911,9.22-3.428,14.13,1.158,4.124,5.421,7.506,9.451,8.433,4.587,1.2,9.637.324,13.019-2.826v2.5H265.53l.031-26.833A15.944,15.944,0,0,0,263.944,36.445Z" transform="translate(-120.353 -15.397)" fill="none" stroke="#dd121f" stroke-width="0.395"/>
                        </g>
                    </svg>
                </div>
                <div v-for="(audio,indexo) in audios.slice(index,index+1)" :key="indexo" class="mb-4">
                    <h2 class="magazine-title font-bold text-red-500">
                        {{ magazine.title }}
                        <svg @click="downloadAll($event)" class="download-all cursor-pointer m-auto" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="22.719" height="20" viewBox="0 0 22.719 20">
                        <g id="Download" transform="translate(0 -2)">
                            <path id="Download-2" data-name="Download" d="M3.525,20A3.4,3.4,0,0,1,0,16.732V15.279A1.135,1.135,0,0,1,1.175,14.19,1.135,1.135,0,0,1,2.35,15.279v1.453a1.135,1.135,0,0,0,1.175,1.089H19.193a1.135,1.135,0,0,0,1.175-1.089V15.279a1.179,1.179,0,0,1,2.35,0v1.453A3.4,3.4,0,0,1,19.193,20Zm7.04-6.174-3.519-3.52a1.244,1.244,0,1,1,1.76-1.76l1.509,1.509V1.244a1.244,1.244,0,1,1,2.488,0V9.829l1.282-1.282a1.244,1.244,0,1,1,1.76,1.76l-3.519,3.52a1.246,1.246,0,0,1-1.76,0Z" transform="translate(0 2)" />
                        </g>
                      </svg>
                    </h2>
                    <h3 class="text-xl font-semibold">{{audio.name}}</h3>
                    <p class="text-sm">{{audio.level}}</p>
                    <div class="level-badge">B2</div>
                </div> 
            </div>
            <div class="quick-actions flex w-4/5 m-auto justify-center items-center mt-12 mb-12">
                <div class="action text-grey-darker hover:bg-gray-300 p-3">
                    <svg @click="random = !random" :class="random ? 'text-red-500':''" class="w-16 h-10 cursor-pointer" fill="currentColor" 
                        xmlns="http://www.w3.org/2000/svg" width="36" height="25.2" viewBox="0 0 36 25.2">
                        <g id="Shuffle" transform="translate(0 -5)">
                            <path id="Shuffle-2" data-name="Shuffle" d="M27.818,22.292h-1.54c-4.508,0-8.414-1.919-11.664-5.709q.143-.235.283-.477a22.082,22.082,0,0,1,1.794-2.537c2.687,3.255,5.86,4.846,9.587,4.846h1.54V15.508L36,20.354,27.818,25.2ZM0,22.292V18.416H1.636c4.6,0,8.07-2.13,10.586-6.481C15.883,5.948,20.6,2.908,26.278,2.908h1.54V0L36,4.846,27.818,9.692V6.784h-1.54c-4.593,0-8.343,2.416-11.38,7.384-3.107,5.372-7.592,8.124-13.262,8.124ZM1.636,6.784H0V2.908H1.636A14.178,14.178,0,0,1,13.191,8.516q-.5.707-.969,1.481a17.183,17.183,0,0,1-1.066,1.634A11.207,11.207,0,0,0,1.636,6.784Z" transform="translate(0 5)" />
                        </g>
                    </svg>
                </div>
                <div class="action text-grey-darker hover:bg-gray-300 p-3">
                    <svg @click="prevButton ? previous() : ''" class="w-16 h-10 cursor-pointer" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg" width="20" height="21.375" viewBox="0 0 20 21.375">
                    <path id="Sustracción_2" data-name="Sustracción 2" d="M1.118,0A1.378,1.378,0,0,0,0,.566V20.81a1.379,1.379,0,0,0,1.116.566,1.4,1.4,0,0,0,.688-.182L15.5,13.38v5.745a2.25,2.25,0,0,0,4.5,0V2.25a2.25,2.25,0,0,0-4.5,0V7.995L1.8.182A1.388,1.388,0,0,0,1.118,0Z" transform="translate(20 21.375) rotate(180)" />
                    </svg>
                </div>
                <div class="action play text-white p-4 rounded-full shadow-lg">
                    <svg v-if="!pauseTrack" @click="play()" class="w-16 h-16 cursor-pointer"
                        xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                        <g id="Grupo_54" data-name="Grupo 54" transform="translate(-156 -254)">
                            <path id="Play" d="M30.388,19.944l-27,15.75A2.25,2.25,0,0,1,0,33.749V2.25A2.25,2.25,0,0,1,3.384.306l27,15.75a2.25,2.25,0,0,1,0,3.888Z" transform="translate(177 268)" fill="#fff"/>
                        </g>
                    </svg>
                    <svg v-else @click="pause()" class="pause-icon w-8 h-8 cursor-pointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M5 4h3v12H5V4zm7 0h3v12h-3V4z"/></svg>
                </div>
                <div class="action text-grey-darker hover:bg-gray-300 p-3">
                    <svg @click="nextButton ? next() : ''" class="w-16 h-10 cursor-pointer" fill="currentColor" 
                        xmlns="http://www.w3.org/2000/svg" width="20" height="21.375" viewBox="0 0 20 21.375">
                        <path id="Sustracción_1" data-name="Sustracción 1" d="M1513.118,300.375a1.378,1.378,0,0,1-1.118-.566V279.566a1.379,1.379,0,0,1,1.116-.566,1.4,1.4,0,0,1,.688.182l13.7,7.813V281.25a2.25,2.25,0,0,1,4.5,0v16.875a2.25,2.25,0,0,1-4.5,0V292.38l-13.7,7.813A1.388,1.388,0,0,1,1513.118,300.375Z" transform="translate(-1512 -279)" />
                    </svg>
                </div>
                <div class="action text-grey-darker hover:bg-gray-300 p-3">
                    <svg @click="repeat = !repeat" :class="repeat ? 'text-red-500':''" class="w-16 h-10 cursor-pointer" fill="currentColor" 
                        xmlns="http://www.w3.org/2000/svg" width="31" height="26" viewBox="0 0 31 26">
                        <g id="Repeat" transform="translate(0.175 -3)">
                            <path id="Repeat-2" data-name="Repeat" d="M0,21.667l7.44-4.334v2.575h9.1c7.707,0,11.157-2.794,11.157-8.642H31c0,7.987-5.09,12.109-14.464,12.109H7.44V26Zm0-6.933C0,6.747,5.09,2.625,14.464,2.625h9.1V0L31,4.334,23.56,8.667V6.092h-9.1c-7.707,0-11.157,2.794-11.157,8.642Z" transform="translate(-0.175 3)" />
                        </g>
                    </svg>
                </div>
            </div>
      </div>
      <div class="player-items flex-row md:flex-col w-full md:w-7/12">
          <ul class="play-items-list w-full overflow-auto m-auto pt-6 pb-6" style="max-height:100%" id="journal-scroll">
              <li @click="selectSound(indexo)" :style="indexo == index ? '' : ''" :class="indexo == index ? 'selected bg-red text-white':''" class="flex py-2 rounded cursor-pointer m-auto" v-for="(audio,indexo) in audios" :key="indexo">
                  <div class="item-number w-1/5 font-semibold m-auto">
                      {{indexo + 1}}
                  </div>
                  <div class="item-text w-3/5 font-semibold text-left m-auto">
                      <div class="item-name font-semibold text-sm">
                          <p>{{audio.name}}</p>
                          <p class="item-level text-xs">{{audio.level}}</p>
                      </div>
                  </div>
                  <div class="item-actions w-1/5 m-auto flex">
                    <div class="w-1/2">
                        <div class="item-action play text-white p-4 rounded-full cursor-pointer m-auto">
                            <svg v-if="!state.audioPlaying[indexo]" class="w-8 h-8" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                                <g id="Grupo_54" data-name="Grupo 54" transform="translate(-156 -254)">
                                    <path id="Play" d="M30.388,19.944l-27,15.75A2.25,2.25,0,0,1,0,33.749V2.25A2.25,2.25,0,0,1,3.384.306l27,15.75a2.25,2.25,0,0,1,0,3.888Z" transform="translate(177 268)" />
                                </g>
                            </svg>
                            <svg v-else class="pause-icon w-8 h-8 cursor-pointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M5 4h3v12H5V4zm7 0h3v12h-3V4z"/></svg>
                        </div>
                    </div>
                    <div class="w-1/2">
                      <svg @click="downloadSound(indexo, $event)" class="cursor-pointer m-auto" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="22.719" height="20" viewBox="0 0 22.719 20">
                        <g id="Download" transform="translate(0 -2)">
                            <path id="Download-2" data-name="Download" d="M3.525,20A3.4,3.4,0,0,1,0,16.732V15.279A1.135,1.135,0,0,1,1.175,14.19,1.135,1.135,0,0,1,2.35,15.279v1.453a1.135,1.135,0,0,0,1.175,1.089H19.193a1.135,1.135,0,0,0,1.175-1.089V15.279a1.179,1.179,0,0,1,2.35,0v1.453A3.4,3.4,0,0,1,19.193,20Zm7.04-6.174-3.519-3.52a1.244,1.244,0,1,1,1.76-1.76l1.509,1.509V1.244a1.244,1.244,0,1,1,2.488,0V9.829l1.282-1.282a1.244,1.244,0,1,1,1.76,1.76l-3.519,3.52a1.246,1.246,0,0,1-1.76,0Z" transform="translate(0 2)" />
                        </g>
                      </svg>
                    </div>
                  </div>       
              </li>
          </ul>
      </div>
    </div>
    <div class="player-actions w-full relative z-10">
        <div class="timer flex w-11/12 h-full items-center justify-around m-auto">
            <div class="md:w-2/12 md:flex items-center hidden" v-for="(audio,indexo) in audios.slice(index, index + 1)" :key="indexo">
                <div class="flex flex-col font-semibold" style="text-align: left; overflow: hidden; white-space: nowrap;">
                    <p style="overflow: hidden; text-overflow: ellipsis;">{{audio.name}}</p>
                    <p class="text-xs">{{audio.level}}</p>
                </div>
            </div>
            <div class="w-4/5 flex md:w-8/12 items-center timeline-holder">
                <div class="w-3/12 md:w-2/12 font-semibold">
                    <p>{{timer}}</p>
                </div>
                <div class="mt-1 relative w-8/12 md:w-11/12">
                    <div @click="seek($event)" ref="progress" class="timeline h-1 bg-grey-dark cursor-pointer rounded-full bg-gray-500">
                        
                        <div class="timeline-listened flex w-full justify-end h-1 bg-red rounded-full relative" :style="{'width' : step + '%'}">
                            
                        </div>
                        
                    </div>
                    <div class="flex w-full justify-end h-1 rounded-full relative" :style="{'width' : step + '%'}">
                        <span id="progressButtonTimer" class="w-3 h-3 md:w-4 md:h-4 bg-red absolute pin-r pin-b -mb-1 rounded-full shadow"></span>
                    </div>
                   
                </div>
                <div class="w-3/12 md:w-2/12 text-grey-darker font-semibold">
                    <p>{{duration}}</p>
                </div>
            </div>
            
            <div :class="mutePlayer ? 'muted volume w-1/5 flex md:w-2/12 m-auto items-center' : 'volume w-1/5 flex md:w-2/12 m-auto items-center'">
                <div class="w-5/12 md:w-3/12 hover:bg-gray-500 rounded-full md:p-1" @click="mute()">
                    <svg v-if="mutePlayer" fill="currentColor" class="muted m-auto cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="21.031" height="15" viewBox="0 0 21.031 15">
                        <g id="Grupo_23" data-name="Grupo 23" transform="translate(786 -619)">
                            <path id="Unión_1" data-name="Unión 1" d="M-108.926,43.128a1.218,1.218,0,0,1-.006-1.783,7.307,7.307,0,0,0-.031-10.7,1.218,1.218,0,0,1-.005-1.783,1.373,1.373,0,0,1,1.873.005,9.743,9.743,0,0,1,.042,14.262,1.348,1.348,0,0,1-.932.367A1.364,1.364,0,0,1-108.926,43.128Zm-7.264.244-5.121-4.039H-125V36.008c0-.005,0-.01,0-.015v-2.91h3.16l5.65-4.456a1.023,1.023,0,0,1,.5-.128.962.962,0,0,1,.985.938V42.562a.9.9,0,0,1-.134.473,1,1,0,0,1-.852.465A1.02,1.02,0,0,1-116.19,43.372Zm3.614-3.072a1.217,1.217,0,0,1,0-1.783,3.654,3.654,0,0,0-.016-5.348,1.218,1.218,0,0,1-.006-1.783,1.373,1.373,0,0,1,1.873.005,6.089,6.089,0,0,1,.026,8.913,1.349,1.349,0,0,1-.932.367A1.363,1.363,0,0,1-112.576,40.3Z" transform="translate(-661 590.5)" />
                        </g>
                    </svg>
                    <svg v-else fill="currentColor" class="m-auto cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="21.031" height="15" viewBox="0 0 21.031 15">
                        <g id="Grupo_23" data-name="Grupo 23" transform="translate(786 -619)">
                            <path id="Unión_1" data-name="Unión 1" d="M-108.926,43.128a1.218,1.218,0,0,1-.006-1.783,7.307,7.307,0,0,0-.031-10.7,1.218,1.218,0,0,1-.005-1.783,1.373,1.373,0,0,1,1.873.005,9.743,9.743,0,0,1,.042,14.262,1.348,1.348,0,0,1-.932.367A1.364,1.364,0,0,1-108.926,43.128Zm-7.264.244-5.121-4.039H-125V36.008c0-.005,0-.01,0-.015v-2.91h3.16l5.65-4.456a1.023,1.023,0,0,1,.5-.128.962.962,0,0,1,.985.938V42.562a.9.9,0,0,1-.134.473,1,1,0,0,1-.852.465A1.02,1.02,0,0,1-116.19,43.372Zm3.614-3.072a1.217,1.217,0,0,1,0-1.783,3.654,3.654,0,0,0-.016-5.348,1.218,1.218,0,0,1-.006-1.783,1.373,1.373,0,0,1,1.873.005,6.089,6.089,0,0,1,.026,8.913,1.349,1.349,0,0,1-.932.367A1.363,1.363,0,0,1-112.576,40.3Z" transform="translate(-661 590.5)" />
                        </g>
                    </svg>
                </div>  
                <div class="w-7/12 md:w-9/12 mt-1 relative">
                    <div @click="volume($event)" ref="volBar" class="timeline h-1 bg-grey-dark cursor-pointer rounded-full bg-gray-500 m-auto relative" style="width:100%">
                        
                        <div class="timeline-listened flex justify-end h-1 rounded-full relative" :style="{'width' : volumeProgress + '%'}">

                        </div>
                        
                    </div>
                    <div class="flex justify-end h-1 rounded-full relative" :style="{'width' : volumeProgress + '%'}">
                        <span id="progressButtonVolume" class="w-3 h-3 md:w-4 md:h-4 absolute pin-r pin-b -mb-1 rounded-full shadow"></span>
                    </div>
                </div>
            </div>
       </div>
       <div class="footer">
            <div @click="toggleLegal" class="legal">
                <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z" />
                </svg>
            </div>
            <a href="https://www.rba.es/general/aviso-legal-revistas_177" title="Aviso legal" target="_blank">Aviso legal</a>
            <a href="https://www.rba.es/politica-privacidad" title="Staff" target="_blank">Política de privacidad</a>
            <a href="javascript:Didomi.preferences.show()">Gestiona tus cookies</a>
            <a href="https://www.rba.es/general/politica-cookies_200" target="_blank">Política de cookies</a>
        </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import { Howl, Howler } from 'howler'

export default {
    mounted() {
        //console.log('ITS MOUNTED!!')
        var barWidth = (0.9 * 100) / 100;
        this.sliderBtnVol = (this.volBar.offsetWidth * barWidth + this.volBar.offsetWidth * 0.05 - 25);

        let number = this.route.params.number
        let token = this.route.params.token
        //console.log(this.route.params.number)
        //console.log(this.route.params.token)

        if (number == '' || token == '') {
            this.$router.push('/forbidden')
            return
        }

        var computedToken = this.$md5(`SpeakUp${number}Player`)
        if (token != computedToken) {
            this.$router.push('/forbidden')
            return
        }

        let indexfile = `/magazines/${number}/index.json`
        fetch(indexfile)
        .then(response => response.json())
        .then(data => {
            //console.log(data)
            this.magazine = {title: data.title, zip: `/magazines/${number}/${data.zip}` }
            document.title = this.magazine.title + ' · SpeakUp Player';

            this.audios = (data.audios.map(function(a) { 
                return { name: a.name, file: `/magazines/${number}/${a.file}`, level: a.level, howl: null }
            }))

            var track = parseInt(location.hash.replace('#', '') - 1)
            //console.log('current track: ', this.index)
            if (track > 0 && track < this.audios.length) this.index = track

            //console.log("THE AUDIOS", this.audios)
            var sound = this.audios[this.index].howl

            this.focusSelectedAudio(this.index)
        })
    },
    setup() {
        //console.log('ITS SETUP!!')
        const route = useRoute()

        const magazine = ref({})
        const number = route.params.number
        const token = route.params.token
        const audios = ref([])
        const step =  ref(0);
        const nextButton = ref(true);
        const prevButton = ref(true);
        const random = ref(false);
        const repeat = ref(false);
        const index = ref(0);
        const duration = ref('00:00');
        const timer = ref('00:00');
        const pauseTrack = ref(false);
        const progress = ref(null);
        const volBar = ref(null);
        const sliderBtn = ref(0);
        const sliderBtnVol = ref(null);
        const volumeProgress = ref(90);
        const mutePlayer = ref(false);
        const state = reactive({
            audioPlaying: []
        })
        const showLegal = ref(false);

        function snakeFormat(text) {         
            var textCleaned = text.toLowerCase();
            textCleaned = textCleaned.replace(/(,|\.|;|:)/g, '');
            textCleaned = textCleaned.replace(/\s+/g, '_');
            textCleaned = textCleaned.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            return textCleaned;
        }

        function formatTime(secs) {
            var minutes = Math.floor(secs / 60) || 0;
            var seconds = Math.floor(secs - minutes * 60) || 0;

            return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
        }

        function percentDuration(seek,duration) {
            var stepValue = (seek * 100) / duration;
            var percentage = Math.floor(stepValue);
            return percentage;
        }

        function play() {
            var sound;
            var audio = audios.value[index.value];
            if (audio.howl) {
                sound = audio.howl
            } else {
                state.audioPlaying[index.value] = false;
                //console.log('Audio: ', audio.file);
                sound = audio.howl =  new Howl({
                    src: [audio.file],
                    html5: true, // A live stream can only be played through HTML5 Audio.
                    format: ['mp3', 'aac'],
                    onplay: function() {
                        pauseTrack.value = true; 
                        nextButton.value = true;
                        prevButton.value = true;
                        duration.value = formatTime(sound.duration());
                        requestAnimationFrame(stepFunction.bind(this));

                        if (dataLayer) {
                            var seek = sound.seek();
                            dataLayer.push({
                                'event': 'custom_event',
                                'event_name': seek == 0 ? 'audio_start' : 'audio_play',
                                'audio_current_time': Math.round(seek),
                                'audio_duration': Math.round(sound.duration()), // duración seg total
                                'audio_percent': percentDuration(seek,sound.duration()),
                                'audio_title': snakeFormat(audio.name),
                                'audio_url': audio.file
                            });

                        }
                    },
                    onpause: function() {
                        pauseTrack.value = false;

                        if (dataLayer) {
                            var seek = sound.seek();
                            dataLayer.push({
                                'event': 'custom_event',
                                'event_name': 'audio_pause',
                                'audio_current_time': Math.round(seek),
                                'audio_duration': Math.round(sound.duration()), // duración seg total
                                'audio_percent': percentDuration(seek,sound.duration()),
                                'audio_title': snakeFormat(audio.name),
                                'audio_url': audio.file
                            });

                        }
                    },
                    onend: function() {
                        
                        if (dataLayer) {
                            dataLayer.push({
                                'event': 'custom_event',
                                'event_name': 'audio_complete',
                                'audio_current_time': Math.round(sound.duration()),
                                'audio_duration': Math.round(sound.duration()), // duración seg total
                                'audio_percent': 100,
                                'audio_title': snakeFormat(audio.name),
                                'audio_url': audio.file
                            });
                        }

                        next();
                    },
                    onseek: function() {
                        window.requestAnimationFrame(stepFunction.bind(this));
                    }
                });
            }
            
            sound.play();
            state.audioPlaying[index.value] = true;
            location.hash = index.value + 1;
        }

        function pause(indexo) {
            var audio = audios.value[index.value];
            var sound = audio.howl;

            if (audio && sound) {
                sound.pause();
                pauseTrack.value = false; 
                state.audioPlaying[index.value] = false;
            }
        }

        function stepFunction() {
            var audio = audios.value[index.value];
            var sound = audio.howl;
            var seek = sound.seek();
            timer.value = formatTime(Math.round(seek)); 
            step.value = (seek * 100) / sound.duration();
            
            var percentage = percentDuration(seek,sound.duration());
            if (dataLayer) {
                if (!isNaN(percentage) && (!audio.percentage || audio.percentage != percentage)) {
                    audio.percentage = percentage

                    if (audio.percentage == 10 || audio.percentage == 25 || audio.percentage == 50 || audio.percentage == 75) {
                            dataLayer.push({
                                'event': 'custom_event',
                                'event_name': 'audio_progress',
                                'audio_current_time': Math.round(seek),
                                'audio_duration': Math.round(sound.duration()), // duración seg total
                                'audio_percent': percentDuration(seek,sound.duration()),
                                'audio_title': snakeFormat(audio.name),
                                'audio_url': audio.file
                            });
                    }
                }
            }
            
            sliderBtn.value = (progress.value.offsetWidth * (step.value/100) + progress.value.offsetWidth * 0.05 - 25);
            
            if (sound.playing()) {
                window.requestAnimationFrame(stepFunction.bind(this));
            }
        }

        function seek(event) {
            var per =  event.offsetX / progress.value.clientWidth; 
            var sound = audios.value[index.value].howl;

            if (sound) {
                if (sound.playing()) {
                    //sound.pause();
                    sound.seek(sound.duration() * per);
                    var barWidth = (per * 100) / 100;
                    sliderBtn.value = (progress.value.offsetWidth * barWidth + progress.value.offsetWidth * 0.05 - 25);
                    //sound.play();
                } else {
                    sound.seek(sound.duration() * per);
                    var barWidth = (per * 100) / 100;
                    sliderBtn.value = (progress.value.offsetWidth * barWidth + progress.value.offsetWidth * 0.05 - 25);
                }               
            }
        }

        function next() {
            nextButton.value = false;
            var audio = audios.value[index.value].howl;
            state.audioPlaying[index.value] = false;

            mutePlayer.value ? mutePlayer.value = false : '';
            audio && audio.mute(true) ?  audio.mute(false) : '';

            if (audio && audios.value.length - 1 == index.value) {
                audio.stop();
                repeat.value 
                    ? index.value = index.value 
                    : random.value 
                        ? index.value = Math.floor(Math.random() * audios.value.length) 
                        : index.value = 0
            } else {
                if (audio) {
                    audio.stop();
                }
                repeat.value 
                    ? index.value = index.value 
                    : random.value 
                        ? index.value = Math.floor(Math.random() * audios.value.length) 
                        : index.value ++; 
            }
            play();
            focusSelectedAudio(index.value);            
        }    

        function previous() {
            var audio = audios.value[index.value].howl;
            prevButton.value = false;
            state.audioPlaying[index.value] = false;

            mutePlayer.value ? mutePlayer.value = false : '';
            audio && audio.mute(true) ?  audio.mute(false) : '';

            if (!audio) {
                index.value = audios.value.length - 1;
            } else if (audio && index.value == 0) {
                audio.stop();
                repeat.value 
                    ? index.value = index.value
                    : random.value 
                        ? index.value = Math.floor(Math.random() * audios.value.length) 
                        : index.value = audios.value.length - 1;
            } else if(audio) {
                audio.stop();
                
                repeat.value 
                    ? index.value = index.value 
                    : random.value 
                        ? index.value = Math.floor(Math.random() * audios.value.length) 
                        : index.value --;
            } 
            play();
            focusSelectedAudio(index.value);
        }

        function selectSound(indexSelected) {
            var audio = audios.value[index.value].howl;
            if (audio) {
                audio.stop();
                state.audioPlaying[index.value] = false;
            }
            index.value = indexSelected;
            play();
        }

        function downloadAll(event) {
            event.stopPropagation();
            if (!this.magazine.zip) return;

            if (dataLayer) {
                var zipParts = this.magazine.zip.split('/')

                let fileName = zipParts[zipParts.length - 1].replace(`-${this.token}`, '');
                fileName = fileName.split('.')[0];
                dataLayer.push({
                    'event': 'file_download',
                    'link_url': location.hostname + this.magazine.zip,
                    'file_extension': 'zip',                    
                    'file_name': snakeFormat(fileName),
                    'element': 'player'
                });
            }

            fetch(this.magazine.zip)
            .then(res => res.blob())
            .then(blob => {
                var fileURL = window.URL.createObjectURL(blob);
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                var nameParts = this.magazine.zip.split('/')

                let name = nameParts[nameParts.length - 1].replace(`-${this.token}`, '');
                console.log(name)
                fileLink.setAttribute('download', name);
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            })
        }

        function downloadSound(indexSelected, event) {
            event.stopPropagation();
            var audio = audios.value[indexSelected];

            if (dataLayer) {
                dataLayer.push({
                    'event': 'file_download',
                    'link_url': location.hostname + audio.file,
                    'file_extension': 'mp3',                    
                    'file_name': snakeFormat(audio.name),
                    'element': 'player_list'
                });
            }

            fetch(audio.file)
            .then(res => res.blob())
            .then(blob => {
                var fileURL = window.URL.createObjectURL(blob);
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', audio.name);
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            })
        }

        function volume(event) {
            var per = event.layerX / parseFloat(volBar.value.scrollWidth);
            var barWidth = (per * 100) / 100;
            volumeProgress.value = barWidth * 100;
            sliderBtnVol.value = (volBar.value.offsetWidth * barWidth + volBar.value.offsetWidth * 0.05 - 25);
            Howler.volume(per);
        }

        function mute() {
            var audio = audios.value[index.value].howl;
            if (audio) {
               mutePlayer.value  = !mutePlayer.value;
               mutePlayer.value ? audio.mute(true) : audio.mute(false)
            }
        }

        function focusSelectedAudio(index) {
            if (index >= 0) {
                nextTick(() => {
                    let list = document.querySelector('.play-items-list')
                    let item = document.querySelector('.play-items-list .selected')
                    if (!list || !item) return

                    let st = list.scrollTop
                    let t = item.getBoundingClientRect().top
                    let gap = 300

                    let scrollGap = (st - gap) + t
                    list.scrollTo(0, scrollGap)
                })
            }
        }

        function toggleLegal() {
            this.showLegal = !this.showLegal;
            console.log(this.showLegal)
        }
        
        return{
            play, pause, duration, formatTime, pauseTrack, next, previous
            , index, audios, timer, step, stepFunction, seek, selectSound
            , downloadSound, downloadAll, focusSelectedAudio
            , state, random, repeat
            , progress, volume, volBar, volumeProgress, sliderBtn, mute, mutePlayer
            , sliderBtnVol, nextButton, prevButton, route, magazine, token
            , showLegal, toggleLegal
        }
    }
}
</script>

<style>

#journal-scroll::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
}

#journal-scroll::-webkit-scrollbar-track {
    background-color: rgba(229, 231, 235, var(--bg-opacity));
    cursor: pointer;
}

#journal-scroll::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #a0aec0;
    
}

#progressButtonTimer, #progressButtonVolume {
    margin-top: -8px;
    right:-8px;
}

@media screen and (max-width: 768px) {
  #progressButtonTimer, #progressButtonVolume{
    right: -7px;
  }
}

</style>